import React, { useEffect, useRef } from "react";

import RiooxLogo from "../../Assets/Site-Images/RiooxLogo.png";

const MyNextBigMovesSection = () => {
  const spotlightRefs = useRef([]);

  //   spotlight
  useEffect(() => {
    const handleMouseMove =
      ("mousemove",
      (event) => {
        const { clientX: x, clientY: y } = event;
        spotlightRefs.current.forEach((card) => {
          card.style.setProperty("--x", `${x}px`);
          card.style.setProperty("--y", `${y}px`);
        });
      });
    document.addEventListener("mousemove", handleMouseMove);

    // Cleanup function
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="mx-auto w-11/12 xl:w-[1100px] mt-32 lg:mt-64">
      <div className="grid lg:grid-cols-2 gap-4 lg:gap-8">
        {/* My Next Big Moves */}
        <div className="flex flex-col  gap-4 lg:gap-8">
          <div className="bg-sec-dark rounded-md p-4 lg:p-8 text-white grid gap-2 text-xs text-justify lg:text-lg font-bold">
            My Next Big Moves
          </div>
          <div
            className="spotlight p-[2px] bg-ter-dark relative rounded-md overflow-hidden cursor-pointer"
            ref={(el) => (spotlightRefs.current[0] = el)}
          >
            <div className="relative spotlight-content bg-ter-dark rounded-md p-4 lg:p-8 text-white grid gap-2 text-xs text-justify lg:text-base">
              I am expanding my focus beyond technology to explore exciting
              opportunities across multiple industries. These ventures—ranging
              from a unique coffee brand to a luxury clothing line and
              groundbreaking tech firms—are designed to set new standards in
              innovation and style. Through each initiative, I am committed to
              driving transformative change and creating lasting impact.
            </div>
          </div>
        </div>

        {/* Planned Brands and Ventures: */}
        <div className="flex flex-col  gap-4 lg:gap-8">
          <div className="bg-sec-dark rounded-md p-4 lg:p-8 text-white grid gap-2 text-xs text-justify lg:text-lg font-bold">
            Planned Brands and Ventures:
          </div>

          <div
            className="spotlight p-[2px] bg-[#4c3355] relative rounded-md overflow-hidden cursor-pointer"
            ref={(el) => (spotlightRefs.current[1] = el)}
          >
            <div className="relative spotlight-content bg-[#4c3355] rounded-md p-4 lg:p-8 text-white grid gap-2 text-xs text-justify lg:text-base">
              Productivity Apps: Launching a suite of (MVP) minimally viable
              productivity applications focused on streamlining daily workflows,
              task management, and personal organization. These apps will
              feature clean interfaces, cross-platform synchronization, and
              AI-powered insights to help users reclaim their time, reduce
              digital overwhelm, and achieve better work-life balance through
              simplified digital experiences that emphasize focus over feature
              bloat.
            </div>
          </div>
          <div
            className="spotlight p-[2px] bg-[#554133] relative rounded-md overflow-hidden cursor-pointer"
            ref={(el) => (spotlightRefs.current[2] = el)}
          >
            <div className="relative spotlight-content bg-[#554133] rounded-md p-4 lg:p-8 text-white grid gap-2 text-xs text-justify lg:text-base">
              Coffee Brand: Inspired by giants like Starbucks, Dunkin', and
              Costa, I aim to create a coffee brand that stands for quality and
              innovation, offering a unique blend of traditional and modern
              flavors to coffee aficionados worldwide.
            </div>
          </div>
          <div
            className="spotlight p-[2px] bg-[#333A55] relative rounded-md overflow-hidden cursor-pointer"
            ref={(el) => (spotlightRefs.current[3] = el)}
          >
            <div className="relative spotlight-content bg-[#333A55] rounded-md p-4 lg:p-8 text-white grid gap-2 text-xs text-justify lg:text-base">
              Clothing Line: Drawing inspiration from renowned labels such as
              Fendi, Prada, and Gucci, my clothing brand will focus on luxury
              and exclusivity, blending high fashion with contemporary trends to
              redefine style statements.
            </div>
          </div>
          <div
            className="spotlight p-[2px] bg-[#375533] relative rounded-md overflow-hidden cursor-pointer"
            ref={(el) => (spotlightRefs.current[4] = el)}
          >
            <div className="relative spotlight-content bg-[#375533] rounded-md p-4 lg:p-8 text-white grid gap-2 text-xs text-justify lg:text-base">
              Tech Companies: With aspirations to mirror the influence of tech
              leaders like Google and Microsoft, I plan to establish tech
              companies that pioneer advanced solutions in AI, software
              development, and beyond, setting new benchmarks in technology and
              usability.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyNextBigMovesSection;
