import React from "react";

const AboutSection = () => {
  return (
    <div className="mt-32 lg:mt-64 bg-sec-dark w-full py-32">
      <div className="mx-auto w-11/12 xl:w-[1100px] grid lg:grid-cols-2 gap-4 lg:gap-8">
        <div className="">
          <h1 className="text-lg lg:text-3xl font-bold text-white">
            Hi, This is Syed.
          </h1>
          <p className="text-white mt-8 text-justify">
          As a forward-thinking software engineer and entrepreneur, I am driven by a passion for exploring new technologies and creating impactful solutions. I focus on constant learning and innovation, building products that solve real-world problems and lead to success. My mission is to challenge the status quo, inspire others, and contribute meaningfully to the ever-evolving tech landscape.


          </p>
        </div>
        <div className="bg-ter-dark rounded-md p-4 lg:p-8 text-white grid gap-2 text-xs lg:text-base">
        <p>💻 Experienced Web Developer</p>
        <p>🚀 Focused on Entrepreneurial Growth</p>
        <p>📚 Committed to Continuous Learning</p>
        <p>💡 Innovating in Technology & Business</p>
        <p>⚙️ Solving Complex Problems</p>
        <p>⏩ Driven by Results and Impact</p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
