import React, { useState } from "react";

import { FaUpwork } from "react-icons/fa6";


import LogoLight from "../../Assets/Site-Images/LogoLight.png";

const Navbar = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isShaking, setIsShaking] = useState(false);

  const handleToggle = (e) => {
    e.preventDefault(); // Prevent the toggle from changing
    setShowTooltip(true); // Show the tooltip message

    setIsShaking(true);
    setTimeout(() => setIsShaking(false), 500); // Duration of the shake animation

    // Optionally hide the tooltip after a few seconds
    setTimeout(() => {
      setShowTooltip(false);
    }, 3000); // Hide after 3 seconds
  };

  return (
    <nav className="flex justify-between items-center h-24 mx-auto w-11/12 xl:w-[1100px]">
      <div>
        {/* Logo */}
        <img src={LogoLight} alt="Logo" className="h-10" />
      </div>
      <div className="relative flex gap-5 items-center flex-row-reverse">
        <a
          href="https://www.upwork.com/freelancers/~012525333cc6a775d5?mp_source=share"
          target="_blank"
          className="bg-pri hover:opacity-80 text-white flex items-center justify-center gap-2 rounded-lg shadow-lg transition-colors duration-300 disabled:bg-ter-dark w-[180px] h-10 text-xs" rel="noreferrer"
        >
          <span className="bg-black rounded-full w-7 h-7 flex items-center justify-center">
            <FaUpwork size={15} />
          </span> Hire Me On Upwork
        </a>

        {/* Toggle Between Dark and Light Mode */}
        <input
          type="checkbox"
          checked={true} // Always in dark mode
          onChange={handleToggle}
          disabled={showTooltip}
          className={`hidden md:block toggle-checkbox ${isShaking ? "animate-shake" : ""}`}
        />
        {showTooltip && (
          <div className="absolute top-100 right-0 p-2 mt-2 bg-gray-700 text-white rounded-md shadow-lg w-[200px] ">
            Sorry, we're developers and prefer to live in dark mode!
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
